import React, { useState } from 'react'
import {
  Box, CircularProgress, IconButton, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useGetNotesByUserId, useDeleteNotesByUserId, useAddNotesByUserId, useEditNotesByUserId,
} from '../../../../api/query/notes/notes';
import ConfirmationModal from '../../common/modals/ConfirmationModal';
import { useConfirmationModal } from '../../hooks/useConfirmationModal';
import { NotesModal } from '../../notes/NotesModal';

interface NotesModalState {
  open: boolean;
  mode: 'add' | 'edit';
}
function UserNotes({ userId }: { userId: number }) {
  const [notesModal, setNotesModal] = useState<NotesModalState>({
    open: false,
    mode: 'add',
  });

  const {
    data: notes, isLoading, isError,
  } = useGetNotesByUserId(userId);

  const addNotesMutation = useAddNotesByUserId({
    onSuccess: () => closeNotesModal(),
  });
  const updateNotesMutation = useEditNotesByUserId({
    onSuccess: () => closeNotesModal(),
  });
  const deleteNotesMutation = useDeleteNotesByUserId(userId);

  const {
    modalState,
    handleOpen,
    handleClose,
    handleConfirm,
  } = useConfirmationModal();

  const closeNotesModal = () => {
    setNotesModal((prev) => ({ ...prev, open: false }));
  }

  const openAddModal = () => {
    setNotesModal({ mode: 'add', open: true });
  };

  const openEditModal = () => {
    setNotesModal({ mode: 'edit', open: true });
  };

  const handleNotesSubmit = (content: string) => {
    if (notesModal.mode === 'add') {
      addNotesMutation.mutate({ userId, content });
    } else {
      updateNotesMutation.mutate({ userId, content });
    }
  };

  if (isLoading) return <CircularProgress size={24} />

  if (isError) {
    return <Typography color="error.main">Failed to load user notes</Typography>
  }

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        {!notes ? (
          <>
            <Typography>No notes</Typography>
            <IconButton
              size="small"
              onClick={openAddModal}
            >
              <AddIcon />
            </IconButton>
          </>
        ) : (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography sx={{ whiteSpace: 'pre-line' }}>
              {notes.content}
            </Typography>
            <IconButton
              size="small"
              onClick={openEditModal}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handleOpen({
                title: 'Are you sure you want to delete this note?',
                confirmLabel: 'Delete',
                onConfirm: () => deleteNotesMutation.mutate(),
              })}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <NotesModal
        open={notesModal.open}
        onClose={() => setNotesModal((prev) => ({ ...prev, open: false }))}
        onSubmit={handleNotesSubmit}
        mode={notesModal.mode}
        initialContent={notesModal.mode === 'edit' ? notes?.content || '' : ''}
      />
      <ConfirmationModal
        {...modalState}
        onConfirm={handleConfirm}
        onCancel={handleClose}
      />
    </>
  );
}

export default UserNotes
