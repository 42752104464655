import {
  CardGiftcard,
  SvgIconComponent,
  ViewTimelineOutlined,
} from '@mui/icons-material'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import ComputerIcon from '@mui/icons-material/Computer'
import ConstructionIcon from '@mui/icons-material/Construction'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export interface Menu {
  key: string
  name: string
  widgetTag: string
  path?: string
  title?: string
  icon?: SvgIconComponent
  children?: Menu[]
}

export const AppMenus: Menu[] = [
  {
    key: 'users',
    name: 'Users',
    widgetTag: 'read_user',
    path: '/users',
    icon: PersonOutlineIcon,
  },
  {
    key: 'dialogs',
    name: 'Dialogs',
    widgetTag: 'read_dialog',
    icon: SmsOutlinedIcon,
    children: [
      {
        key: 'dialogs',
        name: 'Dialogs',
        widgetTag: 'read_dialog',
        path: '/dialogs',
        title: 'Dialogs',
      },
      {
        key: 'dialogsExplore',
        name: 'Explore',
        widgetTag: 'read_dialog',
        path: '/dialogs/explore',
        title: 'Explore',
      },
      {
        key: 'dialogsFeatured',
        name: 'Featured',
        widgetTag: 'read_dialog',
        path: '/dialogs/featured',
        title: 'Featured',
      },
    ],
  },
  {
    key: 'moments',
    name: 'Moments',
    widgetTag: 'read_moment',
    icon: ViewTimelineOutlined,
    path: '/moments',
  },
  {
    key: 'reports',
    name: 'Reports',
    widgetTag: 'read_report',
    icon: ReportOutlinedIcon,
    children: [
      {
        key: 'reportUsers',
        name: 'Users',
        widgetTag: 'read_report',
        path: '/reports/users',
        title: 'Reports [Users]',
      },
      {
        key: 'reportDialogs',
        name: 'Dialogs',
        widgetTag: 'read_report',
        path: '/reports/dialogs',
        title: 'Reports [Dialogs]',
      },
      {
        key: 'reportMoments',
        name: 'Moments',
        widgetTag: 'read_report',
        path: '/reports/moments',
        title: 'Reports [Moments]',
      },
      {
        key: 'reportMessages',
        name: 'Messages',
        widgetTag: 'read_report',
        path: '/reports/messages',
        title: 'Reports [Messages]',
      },
      // {
      //   key: 'reportChatMessages',
      //   name: 'Chat Messages',
      //   widgetTag: 'read_report',
      //   path: 'reports/chatMessages',
      //   title: 'Reports [Chat Messages]',
      // },
    ],
  },
  {
    key: 'maintenance',
    name: 'Maintenance',
    widgetTag: 'read_maintenance',
    path: '/maintenance',
    icon: ConstructionIcon,
  },
  {
    key: 'analytics',
    name: 'Analytics',
    widgetTag: 'read_analytics',
    icon: AnalyticsIcon,
    children: [
      {
        key: 'analyticsKpiDB',
        name: 'KPI(DB)',
        widgetTag: 'read_analytics',
        path: '/analytics/kpi/db',
      },
      {
        key: 'analyticsKpiDBPersonalize',
        name: 'KPI(DB)-personalize',
        widgetTag: 'read_analytics',
        path: '/analytics/kpi/db/personalize',
      },
      {
        key: 'analyticsCampaign',
        name: 'Campaign',
        widgetTag: 'read_analytics',
        path: '/analytics/campaign',
      },
      {
        key: 'analyticsMoment',
        name: 'Moment Retention',
        widgetTag: 'read_analytics',
        path: '/analytics/moment',
      },
    ],
  },
  {
    key: 'campaigns',
    name: 'Campaigns',
    widgetTag: 'read_campaign',
    icon: CardGiftcard,
    children: [
      {
        key: 'campaignGiftCodes',
        name: 'GiftCodes',
        widgetTag: 'read_campaign',
        path: '/campaign/giftCodes',
      },
      {
        key: 'campaignPages',
        name: 'Pages',
        widgetTag: 'read_campaign',
        path: '/campaign/pages',
      },
      {
        key: 'campaignSummary',
        name: 'Summary',
        widgetTag: 'read_campaign',
        path: '/campaign/summary',
      },
      {
        key: 'campaignWeeklyMomentCup',
        name: 'WeeklyMomentCup',
        widgetTag: 'read_campaign',
        path: '/campaign/weeklyLikeMoments',
      },
      {
        key: 'campaignRewards',
        name: 'Rewards',
        widgetTag: 'update_campaign',
        path: '/campaign/rewards',
      },
    ],
  },
  {
    key: 'adminUser',
    name: 'Admin',
    widgetTag: 'read_admin',
    icon: AdminPanelSettingsOutlinedIcon,
    children: [
      {
        key: 'adminUsers',
        name: 'Users',
        widgetTag: 'read_admin',
        path: '/admin/users',
      },
      {
        key: 'adminUserRoles',
        name: 'Roles',
        widgetTag: 'read_admin',
        path: '/admin/userRoles',
      },
    ],
  },
  {
    key: 'contentModeration',
    name: 'Operations',
    widgetTag: 'read_content_moderation',
    icon: AdminPanelSettingsIcon,
    children: [
      {
        key: 'textFilters',
        name: 'Text Filter',
        widgetTag: 'read_content_moderation',
        path: '/contentModeration/textFilters',
      },
      {
        key: 'uploadImages',
        name: 'UploadImages',
        widgetTag: 'read_content_moderation',
        path: '/uploadImages',
      },
    ],
  },
  {
    key: 'debug',
    name: 'Debug',
    widgetTag: 'read_debug',
    icon: ComputerIcon,
    children: [
      {
        key: 'revisions',
        name: 'Revisions',
        widgetTag: 'read_debug',
        path: '/revisions',
      },
      {
        key: 'jobs',
        name: 'Jobs',
        widgetTag: 'read_debug',
        path: '/jobs',
      },
    ],
  },
]
