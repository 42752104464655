import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Box, Typography } from '@mui/material'
import secureLocalStorage from 'react-secure-storage'
import { fetchAdminSession } from '../../services/fetch'

function Login() {
  const [values, setValues] = useState({
    userName: '',
    password: '',
    showPassword: false,
    incorrectLogin: false,
    miscErr: false,
  })

  const handleChange = (prop: string): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      onLogIn()
    }
  }

  const onLogIn = () => {
    fetchAdminSession({
      userName: values.userName,
      password: values.password,
    })
      .then(async (res) => {
        if (res.status === 401) {
          setValues({
            ...values,
            incorrectLogin: true,
            miscErr: false,
          })
        } else if (res.status > 400) {
          setValues({
            ...values,
            incorrectLogin: false,
            miscErr: true,
          })
        } else if (res.status === 200) {
          res.json().then((data) => {
            window.location.reload()
          })
        }
      })
      .catch((err) => {
        console.log(err) // eslint-disable-line
      })
  }

  return (
    <Stack
      component="form"
      sx={{
        width: '25ch',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      spacing={2}
      noValidate
    >
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box
          component="img"
          sx={{ height: 25, mr: 1 }}
          src={`${process.env.PUBLIC_URL}/icon.png`}
        />
        <Typography variant="h5" noWrap component="div">
          Helium Admin
        </Typography>
      </Stack>
      <FormControl variant="standard">
        <InputLabel htmlFor="standard-adornment-userName">Username</InputLabel>
        <Input
          id="standard-adornment-userName"
          type="text"
          value={values.userName}
          onChange={handleChange('userName')}
          onKeyDown={handleKeyDown}
        />
      </FormControl>
      <FormControl variant="standard">
        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
        <Input
          id="standard-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          onKeyDown={handleKeyDown}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}
        />
        { values.incorrectLogin && <FormHelperText error id="standard-incorrect-login-helper-text">Username or password is incorrect</FormHelperText> }
        { values.miscErr && <FormHelperText error id="standard-err-helper-text">Please try again later</FormHelperText> }
      </FormControl>
      <Button variant="contained" onClick={onLogIn}>Log in</Button>
    </Stack>
  )
}

export default Login
