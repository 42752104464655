import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'

// DELETE: didn't use now
interface TabsProps {
  children: React.ReactNode
}

interface TabProps {
  label: string
}

interface TabPanelProps {
  children?: React.ReactNode
}

const callbacks: { [key: number]: ((index: number) => void)[] } = {}
let tabId: number
let tabPanelIndex: number

export function Tabs(props: TabsProps) {
  const { children } = props
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const refTabId = useRef(Date.now())

  tabId = refTabId.current
  tabPanelIndex = 0

  function handleChange(event: React.SyntheticEvent, value: number) {
    changeTabIndex(refTabId.current, value)
  }

  useEffect(() => {
    const id = refTabId.current

    addSetTabIndex(id, setSelectedTabIndex)

    return () => {
      deleteSetTabIndex(id)
    }
  }, [])

  return (
    <MuiTabs
      value={selectedTabIndex}
      onChange={handleChange}
      sx={{
        '& .MuiTabs-flexContainer': {
          flexWrap: 'wrap',
        },
        '& .MuiTab-root': {
          fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
          padding: { xs: '6px 8px', sm: '12px 16px' },
          minWidth: { xs: '90px', sm: '120px', md: '160px' },
          minHeight: { xs: '35px', sm: '48px' },
        },
        '& .MuiTabs-indicator': {
          height: { xs: 2, sm: 3 },
        },
      }}
    >
      {children}
    </MuiTabs>
  )
}

export function Tab(props: TabProps) {
  const { label, ...other } = props
  const refTabId = useRef(tabId)
  const refTabIndex = useRef(0)

  Object.entries(other).forEach(([key, value]) => {
    if (key === 'value' && typeof value === 'number') {
      refTabIndex.current = value
    }
  })

  return (
    <MuiTab
      id={makeTabName(refTabId.current, refTabIndex.current)}
      aria-controls={makeTabPanelName(refTabId.current, refTabIndex.current)}
      label={label}
      {...other}
    />
  )
}

export function TabPanel(props: TabPanelProps) {
  const { children } = props
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [tabIndex, setTabIndex] = useState(-1)
  const refTabId = useRef(tabId)
  const displayed = useRef(false)

  useEffect(() => {
    setTabIndex(tabPanelIndex++)
    addSetTabIndex(refTabId.current, setSelectedTabIndex)
  }, [])

  if (!displayed.current && tabIndex === selectedTabIndex) {
    displayed.current = true
  }

  return (
    <div
      id={makeTabPanelName(refTabId.current, tabIndex)}
      aria-labelledby={makeTabName(refTabId.current, tabIndex)}
      role="tabpanel"
      hidden={tabIndex !== selectedTabIndex}
      style={{ height: '100%' }}
    >
      {displayed.current
        && <Box sx={{ pt: 3, height: '100%' }}>{children}</Box>}
    </div>
  )
}

function makeTabName(id: number, index: number): string {
  return `simple-tab-${id}-${index}`
}

function makeTabPanelName(id: number, index: number): string {
  return `simple-tabpanel-${id}-${index}`
}

function addSetTabIndex(id: number, fn: (index: number) => void) {
  if (callbacks[id] === undefined) {
    callbacks[id] = []
  }

  callbacks[id].push(fn)
}

function deleteSetTabIndex(id: number) {
  delete callbacks[id]
}

function changeTabIndex(id: number, index: number): void {
  for (const fn of callbacks[id]) {
    fn(index)
  }
}
