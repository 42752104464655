import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { TextField } from '@mui/material'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  notes?: string
  userId?: number
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function EditNotesModal(props: Props) {
  const {
    notes, userId, open, handleClose,
  } = props

  const [newNotes, setNewNotes] = React.useState(' ')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewNotes(event.target.value)
  }

  const updateNote = async () => {
    await fetchAdmin({
      path: `notes/${userId}`,
      method: 'PUT',
      body: {
        content: newNotes,
      },
    })
    handleClose(true)
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      maxWidth="xl"
      sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: '100%' } }}
    >
      <DialogTitle>
        Update note
      </DialogTitle>
      <DialogContent>
        <DialogContentText />
        <TextField
          autoFocus
          margin="dense"
          label="Note"
          required
          defaultValue={notes}
          multiline
          fullWidth
          variant="standard"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button disabled={newNotes === ''} onClick={updateNote} color="info">Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditNotesModal
