import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import {
  MRT_PaginationState as MrtPaginationState,
  MRT_SortingState as MrtSortingState,
  MRT_ColumnFiltersState as MrtColumnFiltersState,
} from 'material-react-table';
import { FilterParams, QueryParams } from '../../../api/query/params';

interface TableSearchParams {
  defaultInit?: URLSearchParamsInit;
  extraParams?: Record<string, any>;
}

export function useBaseTableSearchParams({
  defaultInit,
  extraParams = {},
}: TableSearchParams = {}) {
  const [searchParams, setSearchParams] = useSearchParams(defaultInit);

  const getInitialValues = useCallback(
    () => ({
      pageIndex: parseInt(searchParams.get('page') || '0', 10),
      pageSize: parseInt(searchParams.get('pageSize') || '25', 10),
      sortField: searchParams.get('sortField') || '',
      sortDir: searchParams.get('sortDir') === 'desc',
      showDeleted: searchParams.get('showDeleted') !== 'false',
      searchField: searchParams.get('searchField') || '',
      searchValue: searchParams.get('searchValue') || '',
      filterField: searchParams.get('filterField') || '',
      filterOp: searchParams.get('filterOp') || '',
      filterValue: searchParams.get('filterValue') || '',
      ...Object.fromEntries(
        Object.entries(extraParams).map(([key, defaultValue]) => [
          key,
          searchParams.get(key) || defaultValue,
        ]),
      ),
    }),
    [searchParams, extraParams],
  );

  const initialValues = useMemo(() => getInitialValues(), [getInitialValues]);

  const [pagination, setPagination] = useState<MrtPaginationState>({
    pageIndex: initialValues.pageIndex,
    pageSize: initialValues.pageSize,
  });

  const [sorting, setSorting] = useState<MrtSortingState>(
    initialValues.sortField
      ? [{ id: initialValues.sortField, desc: initialValues.sortDir }]
      : [],
  );

  const [columnFilters, setColumnFilters] = useState<MrtColumnFiltersState>(
    initialValues.searchField
      ? [
        {
          id: initialValues.searchField,
          value: initialValues.searchValue,
        },
      ]
      : [],
  );

  const [showDeleted, setShowDeleted] = useState(initialValues.showDeleted);

  const [filter, setFilter] = useState<FilterParams>({
    filterField: initialValues.filterField,
    filterOp: initialValues.filterOp,
    filterValue: initialValues.filterValue,
  });

  const queryParams = useMemo<QueryParams>(
    () => ({
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      sortField: sorting[0]?.id || '',
      dir: sorting[0]?.desc ? 'desc' : 'asc',
      showDeleted,
      filterField: filter.filterField,
      filterOp: filter.filterOp,
      filterValue: filter.filterValue,
      searchField: columnFilters[0]?.id,
      searchOp: columnFilters[0] ? 'contains' : '',
      searchValues: (columnFilters[0]?.value as string) || '',
    }),
    [pagination, sorting, showDeleted, filter, columnFilters],
  );

  const syncSearchParams = useCallback(() => {
    const newParams = new URLSearchParams();

    if (pagination.pageIndex !== 0) {
      newParams.set('page', pagination.pageIndex.toString());
    }
    if (pagination.pageSize !== 25) {
      newParams.set('pageSize', pagination.pageSize.toString());
    }

    if (sorting[0]?.id) {
      newParams.set('sortField', sorting[0].id);
      newParams.set('sortDir', sorting[0].desc ? 'desc' : 'asc');
    }

    if (filter.filterField) {
      newParams.set('filterField', filter.filterField);
      newParams.set('filterOp', filter.filterOp || '');
      newParams.set('filterValue', filter.filterValue || '');
    }

    if (columnFilters[0]?.id) {
      newParams.set('searchField', columnFilters[0].id);
      newParams.set('searchOp', 'contains');
      newParams.set('searchValue', columnFilters[0]?.value as string);
    }

    Object.entries(extraParams).forEach(([key, value]) => {
      if (value !== undefined && value !== '') {
        newParams.set(key, value.toString());
      }
    });

    const currentParams = new URLSearchParams(searchParams);
    const hasChanges = Array.from(newParams.entries()).some(
      ([key, value]) => currentParams.get(key) !== value,
    )
      || Array.from(currentParams.entries()).some(([key]) => !newParams.has(key));

    if (hasChanges) {
      setSearchParams(newParams);
    }
  }, [
    pagination,
    sorting,
    columnFilters,
    filter,
    extraParams,
    searchParams,
    setSearchParams,
  ]);

  useEffect(() => {
    syncSearchParams();
  }, [syncSearchParams]);

  const clearFilters = useCallback(() => {
    setColumnFilters([]);
    setSorting([]);
    setShowDeleted(false);
    setFilter({ filterField: '', filterOp: '', filterValue: '' });
    setPagination({ pageIndex: 0, pageSize: 25 });
    setSearchParams({});
  }, [setSearchParams]);

  return {
    pagination,
    setPagination,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters,
    showDeleted,
    setShowDeleted,
    filter,
    setFilter,
    queryParams,
    clearFilters,
  };
}
