import { useState, useEffect } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useBaseTableSearchParams } from '../../hooks/useBaseTableSearchParams';
import { USER_TYPES } from '../../../../constants';

interface OptionType {
  value: string;
  label: string;
}

export function useUserTableSearchParams(defaultInit?: URLSearchParamsInit) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showUserType, setOnUserTypeChange] = useState<OptionType[]>(USER_TYPES);

  const baseTableParams = useBaseTableSearchParams({
    defaultInit,
    extraParams: {
      showUserType: showUserType.map((option) => option.value).join(','),
    },
  });

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    const userTypeValues = showUserType.map((type) => type.value);

    if (userTypeValues.length === 0) {
      newParams.delete('showUserType');
    } else {
      newParams.set('showUserType', userTypeValues.join(','));
    }

    setSearchParams(newParams);
  }, [showUserType, searchParams, setSearchParams]);

  return {
    ...baseTableParams,
    showUserType,
    setOnUserTypeChange,
    queryParams: {
      ...baseTableParams.queryParams,
      showUserType:
        showUserType.length > 0
          ? showUserType.map((option) => option.value).join(',')
          : '0',
    },
    clearFilters: () => {
      baseTableParams.clearFilters();
      setOnUserTypeChange(USER_TYPES);
    },
  };
}
