import {
  useMutation, UseMutationOptions, useQuery, useQueryClient,
} from '@tanstack/react-query';
import { QUERY_KEYS } from '../keys';
import { fetchAdmin } from '../../../services/fetch';

interface NotesByUserIdParams {
  userId: number;
  content: string;
}

export function useAddNotesByUserId(options?:UseMutationOptions<void, Error, NotesByUserIdParams>) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: NotesByUserIdParams) => {
      const response = await fetchAdmin({
        path: `notes/${data.userId}`,
        method: 'POST',
        body: data,
      });
      if (!response.ok) {
        throw new Error('Error adding user notes');
      }
      queryClient.invalidateQueries([QUERY_KEYS.NOTES, 'user', data.userId]);
    },
    options,
  );
}

export function useGetNotesByUserId(userId: number) {
  return useQuery({
    queryKey: [QUERY_KEYS.NOTES, 'user', userId],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: `notes/${userId}`,
        method: 'GET',
      });
      if (response.status === 404) {
        return null;
      }
      if (!response.ok) {
        throw new Error('Error fetching user notes');
      }
      return response.json();
    },
  });
}

export function useEditNotesByUserId(
  options?: UseMutationOptions<void, Error, NotesByUserIdParams>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: NotesByUserIdParams) => {
      const response = await fetchAdmin({
        path: `notes/${data.userId}`,
        method: 'PUT',
        body: data,
      });
      if (!response.ok) {
        throw new Error('Error updating user notes');
      }
      queryClient.invalidateQueries([QUERY_KEYS.NOTES, 'user', data.userId]);
    },
    options,
  );
}

export function useDeleteNotesByUserId(userId: number) {
  const queryClient = useQueryClient();
  return useMutation(async () => {
    const response = await fetchAdmin({
      path: `notes/${userId}`,
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Error deleting user notes');
    }
    queryClient.invalidateQueries([QUERY_KEYS.NOTES, 'user', userId]);
  });
}
