import React, { useCallback, useEffect, useState } from 'react'

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import moment from 'moment'
import DeleteMaintenanceTaskModal from './DeleteMaintenanceTaskModal'
import type { MaintenanceInfo } from './Maintenance'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, {
  expectedWidgetUpdateMaintenanceTag,
  expectedWidgetDeleteMaintenanceTag,
} from '../common/WidgetTagsAuth'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
))

interface Props {
  details: MaintenanceInfo
  goBack: (refreshGrid: boolean) => void
}

function MaintenanceDetails(props: Props) {
  const { details, goBack } = props

  // Task values
  const [description, setDescription] = useState(details.description)
  const [startTime, setStartTime] = useState<moment.Moment | null>(
    details.startTime ? moment(details.startTime) : null,
  )
  const [endTime, setEndTime] = useState<moment.Moment | null>(
    details.endTime ? moment(details.endTime) : null,
  )

  // Modification values
  const [editing, setEditing] = useState(false)
  const [disableSaveButton, setDisableSaveButton] = useState(false)

  const [modified, setModified] = useState(false)

  // Notifications
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const checkData = useCallback(() => {
    if (description === '' || !startTime || !endTime || !editing) {
      setDisableSaveButton(true)
    } else {
      setDisableSaveButton(false)
    }
  }, [description, startTime, endTime, editing])

  useEffect(() => {
    checkData()
  }, [checkData])

  const handleSave: React.MouseEventHandler<HTMLButtonElement> = async () => {
    if (
      startTime == null
      || endTime == null
      || !startTime.toISOString()
      || !endTime.toISOString()
    ) {
      setShowSnackbar(true)
      return
    }

    setEditing(false)
    setDisableSaveButton(true)
    setModified(true)

    await fetchAdmin({
      path: `maintenance/${details.id}`,
      method: 'PUT',
      body: {
        description,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      },
    })
  }

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') return
    setShowSnackbar(false)
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          startIcon={<NavigateBeforeIcon />}
          onClick={() => goBack(modified)}
          sx={{ alignSelf: 'flex-start' }}
        >
          Maintenance
        </Button>

        <Stack direction="row">
          <Typography variant="h3" gutterBottom>
            {`Maintenance Task #${details.id}`}
          </Typography>
        </Stack>
        <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteMaintenanceTag}>
          <Stack direction="row" spacing={2}>
            <Button
              onClick={() => setShowDeleteTaskModal(true)}
              variant="outlined"
              color="error"
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </Stack>
        </WidgetTagsAuth>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent
                sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
              >
                <Typography variant="h4" gutterBottom>
                  Task Details
                </Typography>

                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={!editing}
                  error={description === ''}
                  helperText={description === '' ? 'Required' : ''}
                />

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={(newStartTime) => setStartTime(newStartTime)}
                    disabled={!editing}
                    slotProps={{
                      textField: {
                        sx: { maxWidth: 170 },
                      },
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label="End Time"
                    value={endTime}
                    onChange={(newEndTime) => setEndTime(newEndTime)}
                    disabled={!editing}
                    slotProps={{
                      textField: {
                        sx: { maxWidth: 170 },
                      },
                    }}
                  />
                </LocalizationProvider>
              </CardContent>
              <WidgetTagsAuth
                expectedWidgetTag={expectedWidgetUpdateMaintenanceTag}
              >
                <CardActions>
                  <Button disabled={editing} onClick={() => setEditing(true)}>
                    Edit
                  </Button>
                  <Button
                    disabled={disableSaveButton}
                    onClick={handleSave}
                    color="info"
                  >
                    Save
                  </Button>
                </CardActions>
              </WidgetTagsAuth>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <DeleteMaintenanceTaskModal
        taskId={details.id}
        open={showDeleteTaskModal}
        handleClose={(actionTriggered: boolean) => {
          setShowDeleteTaskModal(false)
          if (actionTriggered) {
            goBack(true)
          }
        }}
      />

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%' }}
        >
          Invalid time
        </Alert>
      </Snackbar>
    </>
  )
}

export default MaintenanceDetails
