import React from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import { useGetSingleUser } from '../../../../api/query/users/users';
import { Tab, TabPanel, Tabs } from '../../../common/Tabs';
import { UserDetailsCard } from './UserDetailsCard';
import { UserMomentsCard } from './UserMomentsCard';
import { UserDialogsCard } from './UserDialogsCard';
import { User } from '../types';
import { UserReportsCard } from './UserReportsCard';

function UserDetails() {
  const { quickbloxUserId } = useParams();

  const userId = parseInt(quickbloxUserId as string);

  const { data, isLoading, isError } = useGetSingleUser(userId);

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError) {
    return <div>Error fetching user</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  const userData: User = {
    ...data.user,
    dialogsCount: data.dialogs.length,
  }

  const dialogsData = data.dialogs;
  const reportsData = data.reports;

  return (
    <>
      <Tabs>
        <Tab label="Details" />
        <Tab label="Moments" />
        <Tab label="Dialogs" />
        <Tab label="Reports" />
      </Tabs>
      <TabPanel><UserDetailsCard user={userData} /></TabPanel>
      <TabPanel><UserMomentsCard quickbloxUserId={userId} /></TabPanel>
      <TabPanel><UserDialogsCard dialogs={dialogsData} /></TabPanel>
      <TabPanel><UserReportsCard reports={reportsData} /></TabPanel>
    </>
  )
}

export default UserDetails
