import { useState, useEffect } from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import eventBus from '../../services/eventBus'
import UserReportDetails from './UserReportDetails'
import UserReportGrid from './UserReportGrid'
import { EVENT } from '../../constants'

export interface UserReportInfo {
  category: string
  createdAt: string
  deletedAt: string | null
  details: string
  id: number
  reporterQuickbloxUserId: number
  reportedQuickbloxUserId: number
  resolvedAt: string | null
  updatedAt: string
}

function UserReports() {
  const navigate = useNavigate()
  const [currReportInfo, setCurrReportInfo] = useState({} as UserReportInfo)
  const [showGrid, setShowGrid] = useState(true)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    function showReportHelper(e: CustomEvent) {
      setShowGrid(false)
      setCurrReportInfo(e.detail)
      navigate(`/reports/users/${e.detail.id}`)
    }
    eventBus.on(EVENT.SHOW_USER_REPORT_DETAILS, showReportHelper)

    function goBackHelper() {
      setRefresh((r) => !r)
      setShowGrid(true)
    }
    eventBus.on('goBackToUserReports', goBackHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_USER_REPORT_DETAILS, showReportHelper)
      eventBus.remove('goBackToUserReports', goBackHelper)
    }
  }, [navigate])

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <UserReportGrid
        refresh={refresh}
        show={showGrid}
      />
      {showGrid ? null : <UserReportDetails reportInfo={currReportInfo} />}
    </Box>
  )
}

export default UserReports
