import moment from 'moment'

export const MAX_USERNAME_LENGTH = 35

export const MAX_ROLE_LENGTH = 16

export const MAX_CATEGORY_LENGTH = 255

export const DIALOG_TYPES = {
  public: 'Open 🌎',
  peekable: 'Peekable 👀',
  private: 'Private 🏖',
  friend: '1 on 1',
}

export const checkIs1on1Chat = (type: 'friend' | 'privateGroup' | 'peekableGroup' | 'publicGroup') => type === 'friend'

export const COLUMN_WIDTH = {
  QUICKBLOX_USER_ID: 70,
  USERNAME: 150,
  DATE_TIME: 155,
  MORE_DETAILS: 65,
  // FOR REPORTS
  ID: 50,
  LONG_QUICKBLOX_USER_ID: 130,
  CATEGORY: 200,
  DETAILS: 150,
}

export const EVENT = {
  // DETAILS
  SHOW_DETAILS: 'showDetails',
  SHOW_USER_DETAILS: 'showUserDetails',
  SHOW_DIALOG_DETAILS: 'showDialogDetails',
  SHOW_VIDEO_DETAILS: 'showVideoDetails',
  SHOW_MOMENT_DETAILS: 'showMomentDetails',
  SHOW_ADMIN_USER_DETAILS: 'showAdminUserDetails',
  SHOW_MAINTENANCE_DETAILS: 'showMaintenanceDetails',
  // REPORTS DETAILS
  SHOW_USER_REPORT_DETAILS: 'showUserReportDetails',
  SHOW_VIDEO_REPORT_DETAILS: 'showVideoReportDetails',
  SHOW_MOMENT_REPORT_DETAILS: 'showMomentReportDetails',
  SHOW_MESSAGE_REPORT_DETAILS: 'showMessageReportDetails',
  SHOW_DIALOG_REPORT_DETAILS: 'showDialogReportDetails',
  SHOW_CHAT_DETAILS: 'showChatMessageDetails',
  // GRIDS
  SHOW_MOMENT_GRID: 'showMomentGrid',
  SHOW_CHAT_GRID: 'showChatGrid',
  // GO BACK
  GO_BACK_TO_GRID: 'goBackToGrid',
}

export type CountryType = {
  code: string
  label: string
}

export const DEFAULT_COUNTRY: CountryType = {
  code: 'others',
  label: 'Others',
}
export const COUNTRIES: readonly CountryType[] = [
  {
    code: 'JP',
    label: 'Japan',
  },
  {
    code: 'US',
    label: 'United States',
  },
  DEFAULT_COUNTRY,
]

export const TIMEZONES = [
  {
    name: 'America/Los_Angeles',
    short: 'PST',
    offset: -7,
  },
  {
    name: 'Asia/Tokyo',
    short: 'JST',
    offset: 9,
  },
  {
    name: 'UTC',
    short: 'UTC',
    offset: 0,
  },
]

const adjustEndDate = (date: string) => moment(date).subtract(1, 'day').endOf('day').format('yyyy-MM-DD HH:mm:ss')
  .toString()

export const EVENTMOMENT = [
  {
    name: 'Default',
    dateFrom: moment().subtract(7, 'day').startOf('day').toString(),
    dateTo: moment().subtract(1, 'day').endOf('day').toString(),
    timezone: 'America/Los_Angeles', // since default timezone layout is PST
  },
  {
    name: 'Halloween',
    dateFrom: '1970-10-15 00:00:00',
    dateTo: adjustEndDate('1970-11-05 00:00:00'),
    timezone: 'America/Los_Angeles',
  },
  {
    name: 'Christmas',
    dateFrom: '1970-12-01 00:00:00',
    dateTo: adjustEndDate('1971-01-01 00:00:00'),
    timezone: 'America/Los_Angeles',
  },
  {
    name: 'New Year',
    dateFrom: '1970-01-01 00:00:00',
    dateTo: adjustEndDate('1970-01-16 00:00:00'),
    timezone: 'America/Los_Angeles',
  },
  {
    name: 'Winter',
    dateFrom: '1970-02-10 00:00:00',
    dateTo: adjustEndDate('1970-02-26 00:00:00'),
    timezone: 'America/Los_Angeles',
  },
  {
    name: 'Furry Friends',
    dateFrom: '1970-03-01 00:00:00',
    dateTo: adjustEndDate('1970-03-16 00:00:00'),
    timezone: 'America/Los_Angeles',
  },
]

export const USER_TYPES = [
  { value: '1', label: 'Normal' },
  { value: '2', label: 'Deleted' },
  { value: '3', label: 'Suspended' },
]

export const USERSTATUS = [
  { value: 'regular', label: 'Regular', badge: '' },
  { value: 'isDebug', label: 'Debug', badge: '' },
  { value: 'isStaff', label: 'Staff', badge: `${process.env.PUBLIC_URL}/staffBadge.png` },
  { value: 'isOfficial', label: 'Official', badge: `${process.env.PUBLIC_URL}/officialBadge.png` },
  { value: 'isAmbassador', label: 'Ambassador', badge: `${process.env.PUBLIC_URL}/ambassadorBadge.png` },
];
