import React, { useState, useCallback } from 'react'
import {
  Box,
  Stack,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material'
import {
  DataGrid,
  GridColumns,
} from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { TIMEZONES } from '../../constants'
import { fetchAdmin } from '../../services/fetch'

interface KPIResponseRow {
  date: string
  [key: string]: number | string
}

interface FormData {
  from: moment.Moment
  to: moment.Moment
  timezone: string
}

function AnalyticsMoment() {
  const [formData, setFormData] = useState<FormData>({
    from: moment().subtract(30, 'days'),
    to: moment(),
    timezone: TIMEZONES[0].name,
  })
  const [rows, setRows] = useState<KPIResponseRow[]>([])
  const [columns, setColumns] = useState<GridColumns>([])
  const [loading, setLoading] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const generateDynamicColumns = useCallback((data: KPIResponseRow[]) => {
    const dynamicColumns: GridColumns = [
      {
        field: 'date',
        headerName: 'Date',
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ row }) => moment(row.date.toString()).format('YYYY/MM/DD'),
      },
      {
        field: 'sum',
        headerName: 'Sum',
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => Object.keys(params.row)
          .filter((key) => !Number.isNaN(Number(key)))
          .reduce((sum, key) => sum + (params.row[key] || 0), 0),
        cellClassName: (params) => getCellClassName(params.value as number),
      },
    ]

    for (let i = 0; i <= 30; i++) {
      dynamicColumns.push({
        field: `${i}`,
        headerName: i === 30 ? 'Sum of Days < 30' : `Day ${i}`,
        width: i === 30 ? 150 : 100,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => params.row[i] || 0,
        cellClassName: (params) => getCellClassName(params.value as number),
      })
    }

    setColumns(dynamicColumns)
    setRows(data)
  }, [])

  const getCellClassName = (value: number): string => {
    if (value === 0) return 'color-0'
    if (value >= 90) return 'color-100-90'
    if (value >= 80) return 'color-90-80'
    if (value >= 70) return 'color-80-70'
    if (value >= 60) return 'color-70-60'
    if (value >= 50) return 'color-60-50'
    if (value >= 40) return 'color-50-40'
    if (value >= 30) return 'color-40-30'
    if (value >= 20) return 'color-30-20'
    return 'color-20-10'
  }

  const onSubmit = useCallback(async () => {
    setLoading(true)
    setHasSubmitted(true)
    try {
      const formattedFormData = {
        from: formData.from.format('YYYYMMDD'),
        to: formData.to.format('YYYYMMDD'),
        timezone: formData.timezone,
      }

      const response: KPIResponseRow[] = await fetchAdmin({
        path: 'analytics/moment/db',
        method: 'GET',
        queries: { form: JSON.stringify(formattedFormData) },
      }).then((res) => res.json())

      generateDynamicColumns(response)
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    } finally {
      setLoading(false)
    }
  }, [formData, generateDynamicColumns])

  const exportToCSV = useCallback(() => {
    const csvContent = [
      columns.map((column) => column.headerName).join(','),
      ...rows.map((row) => columns
        .map((column) => `"${row[column.field as keyof KPIResponseRow] || ''}"`)
        .join(',')),
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `moment-retention-analysis-${moment().format('YYYYMMDD')}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [columns, rows])

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack direction="row" spacing={2} padding={2} alignItems="center">
          <DatePicker
            label="From"
            value={formData.from}
            onChange={(value) => setFormData((prev) => ({ ...prev, from: value! }))}
            format="YYYY/MM/DD"
          />
          <DatePicker
            label="To"
            value={formData.to}
            onChange={(value) => setFormData((prev) => ({ ...prev, to: value! }))}
            format="YYYY/MM/DD"
          />
          <FormControl sx={{ minWidth: 140 }}>
            <InputLabel id="kpi-timezone-select-label">
              timezone
            </InputLabel>
            <Select
              labelId="kpi-timezone-select-label"
              id="kpi-timezone-select"
              value={formData.timezone}
              label="timezone"
              onChange={(event) => setFormData((prev) => ({
                ...prev,
                timezone: event.target.value,
              }))}
            >
              <MenuItem value="UTC">UTC</MenuItem>
              <MenuItem value="America/Los_Angeles">PT</MenuItem>
              <MenuItem value="Asia/Tokyo">JST</MenuItem>
            </Select>
          </FormControl>

        </Stack>
        <Stack direction="row" spacing={2} padding={2}>
          <Button variant="outlined" onClick={onSubmit} disabled={loading}>
            Submit
          </Button>
          <Button variant="outlined" onClick={exportToCSV} disabled={rows.length === 0}>
            Export
          </Button>
        </Stack>
      </LocalizationProvider>
      {hasSubmitted && (
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          loading={loading}
          getRowId={(row) => row.date}
          disableColumnFilter
          disableColumnMenu
          hideFooter
          sx={{
            '& .color-100-90': {
              backgroundColor: '#003f5c',
              color: 'white',
            },
            '& .color-90-80': {
              backgroundColor: '#2f4b7c',
              color: 'white',
            },
            '& .color-80-70': {
              backgroundColor: '#465881',
              color: 'white',
            },
            '& .color-70-60': {
              backgroundColor: '#5a6fa1',
              color: 'white',
            },
            '& .color-60-50': {
              backgroundColor: '#6d87b4',
              color: 'white',
            },
            '& .color-50-40': {
              backgroundColor: '#86a3d2',
              color: 'black',
            },
            '& .color-40-30': {
              backgroundColor: '#9db8e7',
              color: 'black',
            },
            '& .color-30-20': {
              backgroundColor: '#b3cdfa',
              color: 'black',
            },
            '& .color-20-10': {
              backgroundColor: '#d3e5ff',
              color: 'black',
            },
            '& .color-0': {
              backgroundColor: '#ffffff',
              color: 'black',
            },
          }}
          showColumnRightBorder
          showCellRightBorder
        />
      )}
    </Box>
  )
}

export default AnalyticsMoment
