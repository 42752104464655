import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import eventBus from '../../services/eventBus'
import DialogReportDetails from './DialogReportDetails'
import DialogReportGrid from './DialogReportGrid'

export const EVENT_SHOW_DETAILS = 'showDialogReportDetails'
export const EVENT_GO_BACK = 'goBackToDialogReports'

export interface DialogReportInfo {
  category: string
  createdAt: string
  deletedAt: string | null
  details: string
  id: number
  quickbloxDialogId: string
  reporterQuickbloxUserId: number
  reportedQuickbloxUserId: number
  resolvedAt: string | null
  updatedAt: string
}

function DialogReports() {
  const navigate = useNavigate()
  const [reportInfo, setReportInfo] = useState({} as DialogReportInfo)
  const [showGrid, setShowGrid] = useState(true)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    function showDetailsHelper(e: CustomEvent) {
      setShowGrid(false)
      setReportInfo(e.detail)
      navigate(`/reports/dialogs/${e.detail.id}`)
    }

    function goBackHelper() {
      setRefresh((r) => !r)
      setShowGrid(true)
    }

    eventBus.on(EVENT_SHOW_DETAILS, showDetailsHelper)
    eventBus.on(EVENT_GO_BACK, goBackHelper)

    return () => {
      eventBus.remove(EVENT_SHOW_DETAILS, showDetailsHelper)
      eventBus.remove(EVENT_GO_BACK, goBackHelper)
    }
  }, [navigate])

  return (
    <>
      <DialogReportGrid
        refresh={refresh}
        show={showGrid}
      />
      {showGrid ? null : <DialogReportDetails reportInfo={reportInfo} />}
    </>
  )
}

export default DialogReports
