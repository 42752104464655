import { logoutAdmin } from './fetch'
import { getCookie } from './cookieUtils'

export function checkAuth(res: Response) {
  if (res.status === 401) {
    const authToken = getCookie('Authorization')
    if (!authToken) {
      logoutAdmin().then(() => {
        window.location.reload()
      })
    }
  }
  return res
}
