import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../keys';
import { fetchAdmin } from '../../../services/fetch';
import { QueryParams } from '../params';

export function useGetMomentsByUser(
  quickbloxUserId: number,
  params: QueryParams,
) {
  const queryParams = {
    ...params,
  };
  return useQuery({
    queryKey: [QUERY_KEYS.MOMENTS, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: `moments/users/${quickbloxUserId}`,
        method: 'GET',
        queries: queryParams,
      });
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Error fetching moments of the user:', errorData); // eslint-disable-line no-console
      }
      return response.json();
    },
  });
}
