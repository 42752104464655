import { useState, useCallback } from 'react';

interface ConfirmationModalState {
  open: boolean;
  title: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
}

interface UseConfirmationModalReturn {
  modalState: ConfirmationModalState;
  handleOpen: (config: Omit<ConfirmationModalState, 'open'>) => void;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const useConfirmationModal = (): UseConfirmationModalReturn => {
  const [modalState, setModalState] = useState<ConfirmationModalState>({
    open: false,
    title: '',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
  });

  const handleOpen = useCallback(
    (config: Omit<ConfirmationModalState, 'open'>) => {
      setModalState({ ...config, open: true });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setModalState((prev) => ({ ...prev, open: false }));
  }, []);

  const handleConfirm = useCallback(() => {
    if (modalState.onConfirm) {
      modalState.onConfirm();
    }
    handleClose();
  }, [modalState, handleClose]);

  return {
    modalState,
    handleOpen,
    handleClose,
    handleConfirm,
  };
};
