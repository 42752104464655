import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';

interface NotesModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (content: string) => void;
  mode: 'add' | 'edit';
  initialContent: string;
}

export function NotesModal({
  open,
  onClose,
  onSubmit,
  mode,
  initialContent,
}: NotesModalProps) {
  const [content, setContent] = useState(initialContent);

  useEffect(() => {
    if (open) {
      setContent(initialContent);
    }
  }, [initialContent, open]);

  const handleSubmit = () => {
    onSubmit(content);
    setContent('');
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {mode === 'add' ? 'Add New Note' : 'Edit Note'}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Note Content"
          fullWidth
          multiline
          rows={4}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!content.trim()}
        >
          {mode === 'add' ? 'Add Note' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
