import { Block, Delete, NavigateBefore } from '@mui/icons-material'
import {
  Autocomplete,
  Avatar, Box, Button, Card, CardContent, FormControlLabel, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, Checkbox,
  IconButton,
} from '@mui/material'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid'
import {
  useCallback, useEffect, useState, ChangeEvent,
} from 'react'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  COUNTRIES, CountryType, DEFAULT_COUNTRY,
} from '../../constants'
import { fetchAdmin } from '../../services/fetch'
import ConfirmModal from '../common/ConfirmModal'
import { DateCell } from '../common/DataGrid'
import { Tab, TabPanel, Tabs } from '../common/Tabs'
import WidgetTagsAuth, { expectedWidgetUpdateUserTag, expectedWidgetDeleteUserTag } from '../common/WidgetTagsAuth'
import ChangingRegionModal from './ChangingRegionModal'
import DeleteUserModal from './DeleteUserModal'
import SwitchingOfficialModal from './SwitchingOfficialModal'
import SwitchingStaffModal from './SwitchingStaffModal'
import SwitchingAmbassadorModal from './SwitchingAmbassadorModal'
import SwitchingSuspendodal from './SwitchingSuspendModal'
import UserMomentList from './UserMomentList'
import EditNotesModal from './EditNotesModal'
import InsertNotesModal from './InsertNotesModal'

interface Props {
  quickbloxUserId: number
  backText: string
  exitUserDetails: (deletedUser: boolean) => void
}

interface UserDetailsCardProps {
  note?: NoteInfo
  details: UserDetailsInfo
  updateView: () => void
}

interface UserControlButtonsProps {
  details: UserDetailsInfo
  updateView: () => void
}

interface UserDetailsInfo {
  quickbloxUserId: number
  userName: string
  fullName: string
  phone: string
  avatarUrl: string
  bioAudioPath: string
  isOfficial: boolean
  isStaff: boolean
  isAmbassador: boolean
  disabled: boolean
  region: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string
  dialogs: Dialog[]
  reports: Report
}

interface NoteInfo {
  id: number
  userId: number
  content: string
  createdAt: string
  updatedAt: string
  deletedAt: string
}

interface Dialog {
  quickbloxDialogId: string
  type: string
  name: string | null
}

interface Report {
  reporter: SubReport[]
  reported: SubReport[]
}

interface SubReport {
  id: number
  reporterQuickbloxUserId: number
  reportedQuickbloxUserId: number
  category: string
  details: string
  resolvedAt: string
  createdAt: string
  updatedAt: string
  deletedAt: string
}

interface DialogCardProps {
  dialogs: Dialog[]
}

interface ReportCardProps {
  reports: SubReport[]
  quickbloxUserId: number
}

const reportColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 110,
  },
  {
    field: 'reporterQuickbloxUserId',
    headerName: 'Reporter ID',
    width: 150,
  },
  {
    field: 'reportedQuickbloxUserId',
    headerName: 'Reported ID',
    width: 150,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 200,
  },
  {
    field: 'details',
    headerName: 'Details',
    width: 300,
  },
  {
    field: 'resolvedAt',
    headerName: 'Resolved At',
    type: 'dateTime',
    width: 160,
    renderCell: (params: GridRenderCellParams) => {
      const date = params.value
      return formattedDates(date) // (date.toLocaleString(undefined, { hour12: false })).replace(/,/g, '')
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: 160,
    renderCell: (params: GridRenderCellParams) => {
      const date = params.value
      return formattedDates(date) // (date.toLocaleString(undefined, { hour12: false })).replace(/,/g, '')
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'dateTime',
    width: 160,
    renderCell: (params: GridRenderCellParams) => {
      const date = params.value
      return formattedDates(date) // (date.toLocaleString(undefined, { hour12: false })).replace(/,/g, '')
    },
  },
  {
    field: 'deletedAt',
    headerName: 'Deleted At',
    type: 'dateTime',
    width: 160,
    renderCell: (params: GridRenderCellParams) => {
      const date = params.value
      return formattedDates(date) // (date.toLocaleString(undefined, { hour12: false })).replace(/,/g, '')
    },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 250,
    editable: true,
  },
]

function formattedDates(dateStr: string): string {
  if (dateStr === '' || dateStr === null) {
    return ''
  }
  const date = new Date(dateStr)
  const doneFormat = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`
  return doneFormat
}

const dialogColumns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 150,
  },
  {
    field: 'quickbloxDialogId',
    headerName: 'QB Dialog ID',
    width: 300,
  },
]

function UserDetails(props: Props) {
  const { quickbloxUserId, backText, exitUserDetails } = props
  const [details, setDetails] = useState<UserDetailsInfo>()
  const [note, setNotes] = useState<NoteInfo>()
  const updateView = useCallback(() => {
    fetchUser({
      quickbloxUserId,
      setDetails,
    })
    fetchNotes({
      quickbloxUserId,
      setNotes,
    })
  }, [quickbloxUserId])

  useEffect(() => {
    updateView()
  }, [updateView])
  // combine reporter and reported
  const sortedReports = details ? [...details.reports.reported, ...details.reports.reporter].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) : []

  return (
    <>
      {
        backText
          && (
            <Button
              startIcon={<NavigateBefore />}
              onClick={() => exitUserDetails(false)}
            >
              {backText}
            </Button>
          )
      }

      <Tabs>
        <Tab label="details" />
        <Tab label="moments" />
        <Tab label="dialogs" />
        <Tab label="reports" />
      </Tabs>

      <TabPanel>
        {
          details !== undefined
            && (
              <UserDetailsCard
                note={note}
                details={details}
                updateView={updateView}
              />
            )
        }

      </TabPanel>

      <TabPanel>
        <UserMomentList
          quickbloxUserId={quickbloxUserId}
        />
      </TabPanel>

      <TabPanel>
        {
          details !== undefined
            && (
              <DialogsCard
                dialogs={details.dialogs}
              />
            )
        }
      </TabPanel>
      <TabPanel>
        {
          details !== undefined
            && (
              <ReportsCard
                reports={sortedReports}
                quickbloxUserId={quickbloxUserId}
              />
            )
        }
      </TabPanel>
    </>
  )
}

function UserDetailsCard(props: UserDetailsCardProps) {
  const { note, details, updateView } = props

  const [showEditNotes, setShowEditNotes] = useState({
    open: false,
  })

  const [showInsertNotes, setShowInsertNotes] = useState({
    open: false,
  })

  const handleCloseInsertNotesModal = (actionTriggered: boolean) => {
    setShowInsertNotes({ open: false })
    if (actionTriggered) {
      updateView()
    }
  }

  const handleCloseEditNotesModal = (actionTriggered: boolean) => {
    setShowEditNotes({ open: false })
    if (actionTriggered) {
      updateView()
    }
  }

  const handleOpenEditNotesModal = () => {
    setShowEditNotes({ open: true })
  }

  const handleOpenInsertNotesModal = () => {
    setShowInsertNotes({ open: true })
  }
  return (
    <>
      <Stack direction="row">
        <Avatar
          src={details.avatarUrl}
          sx={{ width: 100, height: 100, m: 2 }}
        >
          <Typography variant="h4">
            {details.userName ? details.userName.charAt(0) : '?'}
          </Typography>
        </Avatar>

        <Stack direction="column" justifyContent="center">
          <Typography variant="h4" m={0}>
            {details.fullName}
          </Typography>
          <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            {`@${details.userName}`}
          </Typography>
        </Stack>
      </Stack>

      <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag || expectedWidgetDeleteUserTag}>
        <UserControlButtons
          details={details}
          updateView={updateView}
        />
      </WidgetTagsAuth>

      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Quickblox User ID
                  </TableCell>
                  <TableCell>
                    {details.quickbloxUserId}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Number of Dialogs
                  </TableCell>
                  <TableCell>
                    {details.dialogs.length}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Number of Reports Made
                  </TableCell>
                  <TableCell>
                    {details.reports.reporter.length}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Number of Reported By Other
                  </TableCell>
                  <TableCell>
                    {details.reports.reported.length}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Phone
                  </TableCell>
                  <TableCell>
                    {details.phone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    AvatarUrl
                  </TableCell>
                  <TableCell>
                    {details.avatarUrl}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    BioAudioPath
                  </TableCell>
                  <TableCell>
                    {details.bioAudioPath}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    CreatedAt
                  </TableCell>
                  <TableCell>
                    <DateCell date={details.createdAt} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    UpdatedAt
                  </TableCell>
                  <TableCell>
                    <DateCell date={details.updatedAt} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    DeletedAt
                  </TableCell>
                  <TableCell>
                    <DateCell date={details.deletedAt} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Notes
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'pre-line' }}>
                    {note?.content ? note.content : 'No notes'}
                    <IconButton
                      onClick={() => (note?.content ? handleOpenEditNotesModal() : handleOpenInsertNotesModal())}
                      sx={{ color: 'text.secondary' }}
                    >
                      {note?.content ? <EditIcon /> : <AddIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <InsertNotesModal
        userId={details.quickbloxUserId}
        open={showInsertNotes.open}
        handleClose={handleCloseInsertNotesModal}
      />
      <EditNotesModal
        notes={note?.content}
        userId={note?.userId}
        open={showEditNotes.open}
        handleClose={handleCloseEditNotesModal}
      />
    </>
  )
}

function UserControlButtons(props: UserControlButtonsProps) {
  const { details, updateView } = props
  const [showRegionModal, setShowRegionModal] = useState(false)
  const [newRegion, setNewRegion] = useState(DEFAULT_COUNTRY)
  const [showOfficialModal, setShowOfficialModal] = useState(false)
  const [showStaffModal, setShowStaffModal] = useState(false)
  const [showAmbassadorModal, setShowAmbassadorModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showBanModal, setShowBanModal] = useState(false)
  const [showSuspendModal, setShowSuspendModal] = useState(false)

  function handleOpenRegionModal(region: CountryType) {
    setNewRegion(region)
    setShowRegionModal(true)
  }

  function handleCloseRegionModal(actionTriggered: boolean, region?: string) {
    setShowRegionModal(false)

    if (actionTriggered && region !== undefined) {
      updateView()
    }
  }

  function handelOpenOfficialModal() {
    setShowOfficialModal(true)
  }

  function handleCloseOfficialModal(actionTriggered: boolean, isOfficial?: boolean) {
    setShowOfficialModal(false)

    if (actionTriggered && isOfficial !== undefined) {
      updateView()
    }
  }

  function handleOpenStaffModal() {
    setShowStaffModal(true)
  }

  function handleCloseStaffModal(actionTriggered: boolean, isStaff?: boolean) {
    setShowStaffModal(false)

    if (actionTriggered && isStaff !== undefined) {
      updateView()
    }
  }

  function handleOpenAmbassadorModal() {
    setShowAmbassadorModal(true)
  }

  function handleCloseAmbassadorModal(actionTriggered: boolean, isAmbassador?: boolean) {
    setShowAmbassadorModal(false)

    if (actionTriggered && isAmbassador !== undefined) {
      updateView()
    }
  }

  function handleOpenDeleteModal() {
    setShowDeleteModal(true)
  }

  function handleCloseDeleteModal(actionTriggered: boolean) {
    setShowDeleteModal(false)

    if (actionTriggered) {
      updateView()
    }
  }

  function handleOpenBanModal() {
    setShowBanModal((show) => !show)
  }

  function handleBanUser() {
    fetchAdmin({
      path: `users/${details.quickbloxUserId}/ban`,
      method: 'PUT',
    })
      .then(() => { updateView() })
  }

  function handleOpenSuspendModal() {
    setShowSuspendModal(true)
  }

  function handleCloseSuspendModal(actionTriggered: boolean, disabled?: boolean) {
    setShowSuspendModal(false)

    if (actionTriggered && disabled !== undefined) {
      updateView()
    }
  }

  return (
    <>
      <Stack direction="row" spacing={2} m={1}>
        <Autocomplete
          sx={{ width: 170 }}
          disableClearable
          options={COUNTRIES}
          value={details.region ? COUNTRIES.find((c) => c.code === details.region) : DEFAULT_COUNTRY}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              handleOpenRegionModal(newValue)
            }
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(params, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
              {
                option.code !== 'others'
                  && (
                    <img
                      alt={option.label}
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    />
                  )
              }
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a country"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // NOTE: disable autocomplete and autofill by any browsers suggestion
              }}
            />
          )}
        />
        <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag}>
          <Button
            onClick={handelOpenOfficialModal}
            variant={details.isOfficial ? 'contained' : 'outlined'}
            color="info"
            startIcon={(
              <Box
                component="img"
                sx={{ height: 18 }}
                src={`${process.env.PUBLIC_URL}/officialBadge.png`}
              />
          )}
          >
            Official
          </Button>

          <Button
            onClick={handleOpenStaffModal}
            variant={details.isStaff ? 'contained' : 'outlined'}
            color="info"
            startIcon={(
              <Box
                component="img"
                sx={{ height: 18 }}
                src={`${process.env.PUBLIC_URL}/staffBadge.png`}
              />
          )}
          >
            Staff
          </Button>

          <Button
            onClick={handleOpenAmbassadorModal}
            variant={details.isAmbassador ? 'contained' : 'outlined'}
            color="info"
            startIcon={(
              <Box
                component="img"
                sx={{ height: 18 }}
                src={`${process.env.PUBLIC_URL}/ambassadorBadge.png`}
              />
          )}
          >
            Ambassador
          </Button>

          <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteUserTag}>
            {
              details.isDeleted
                ? (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Delete />}
                  >
                    Deleted already
                  </Button>
                )
                : (
                  <Button
                    onClick={handleOpenDeleteModal}
                    variant="outlined"
                    color="error"
                    startIcon={<Delete />}
                  >
                    Delete
                  </Button>
                )
             }
            <Button
              variant={details.isDeleted ? 'contained' : 'outlined'}
              color="error"
              startIcon={<Delete />}
              onClick={details.isDeleted ? undefined : handleOpenBanModal}
            >
              BAN
            </Button>
          </WidgetTagsAuth>
          <Button
            variant={details.disabled ? 'contained' : 'outlined'}
            color="error"
            startIcon={<Block />}
            onClick={handleOpenSuspendModal}
          >
            {details.disabled ? 'UNSUSPEND' : 'SUSPEND'}
          </Button>
        </WidgetTagsAuth>
      </Stack>

      <ChangingRegionModal
        userId={details.quickbloxUserId}
        name={details.userName}
        oldCountry={COUNTRIES.find((c) => c.code === details.region) || DEFAULT_COUNTRY}
        newCountry={newRegion}
        open={showRegionModal}
        handleClose={handleCloseRegionModal}
      />

      <SwitchingOfficialModal
        userId={details.quickbloxUserId}
        name={details.userName}
        isOfficial={details.isOfficial}
        open={showOfficialModal}
        handleClose={handleCloseOfficialModal}
      />

      <SwitchingStaffModal
        userId={details.quickbloxUserId}
        name={details.userName}
        isStaff={details.isStaff}
        open={showStaffModal}
        handleClose={handleCloseStaffModal}
      />

      <SwitchingAmbassadorModal
        userId={details.quickbloxUserId}
        name={details.userName}
        isAmbassador={details.isAmbassador}
        open={showAmbassadorModal}
        handleClose={handleCloseAmbassadorModal}
      />

      <DeleteUserModal
        userId={details.quickbloxUserId}
        name={details.userName}
        open={showDeleteModal}
        handleClose={handleCloseDeleteModal}
      />

      <ConfirmModal
        openTrigger={showBanModal}
        title={`BAN the user "${details.userName}"?`}
        message="Once BAN, you can&apos;t undo this operation."
        onAccept={handleBanUser}
      />

      <SwitchingSuspendodal
        userId={details.quickbloxUserId}
        name={details.userName}
        disabled={details.disabled}
        open={showSuspendModal}
        handleClose={handleCloseSuspendModal}
      />
    </>
  )
}

function DialogsCard(props: DialogCardProps) {
  const { dialogs } = props

  return (
    <Card>
      <CardContent>
        <DataGrid
          columns={dialogColumns}
          rows={dialogs}
          getRowId={(row) => row.quickbloxDialogId}
          autoHeight
        />
      </CardContent>
    </Card>
  )
}

function ReportsCard(props: ReportCardProps) {
  const { reports, quickbloxUserId } = props
  const [showReporter, setShowReporter] = useState<boolean>(true)
  const [showReported, setShowReported] = useState<boolean>(true)
  const [isReporterDisabled, setReporterDisabled] = useState<boolean>(false)
  const [isReportedDisabled, setReportedDisabled] = useState<boolean>(false)
  const [reporterCount, setCountTer] = useState<number>(0)
  const [reportedCount, setCountTed] = useState<number>(0)

  const handleCheckboxChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    setShowReporter(event.target.checked)
  }
  const handleCheckboxChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    setShowReported(event.target.checked)
  }

  const filteredReports = reports.filter((report) => (showReporter && report.reporterQuickbloxUserId === quickbloxUserId)
   || (showReported && report.reportedQuickbloxUserId === quickbloxUserId))

  useEffect(() => {
    let countTer = 0
    let countTed = 0

    reports.forEach((report) => {
      if (report.reporterQuickbloxUserId === quickbloxUserId) {
        countTer++;
      } else if (report.reportedQuickbloxUserId === quickbloxUserId) {
        countTed++;
      }
    })
    setCountTer(countTer);
    setCountTed(countTed);
    setReporterDisabled(countTer === 0);
    setReportedDisabled(countTed === 0);
  }, [reports, quickbloxUserId])

  return (
    <Card>
      <CardContent>
        <FormControlLabel
          control={<Checkbox checked={showReporter} onChange={handleCheckboxChange1} />}
          label={`Show Reports Made [${reporterCount}]`}
          disabled={isReporterDisabled}
        />
        <FormControlLabel
          control={<Checkbox checked={showReported} onChange={handleCheckboxChange2} />}
          label={`Show Reported By Other [${reportedCount}]`}
          disabled={isReportedDisabled}
        />
        <DataGrid
          columns={reportColumns}
          rows={filteredReports}
          getRowId={(row) => row.id}
          autoHeight
        />
      </CardContent>
    </Card>
  )
}

function fetchUser({
  quickbloxUserId,
  setDetails,
}: {
  quickbloxUserId: number
  setDetails: (details: UserDetailsInfo) => void
}): void {
  fetchAdmin({
    path: `users/${quickbloxUserId}`,
    method: 'GET',
  })
    .then((res) => res.json())
    .then((json) => {
      const { user, dialogs, reports } = json

      setDetails({
        ...user,
        dialogs,
        reports,
        quickbloxUserId,
        avatarUrl: user.customData.avatarUrl,
        isDeleted: !!user.deletedAt,
      })
    })
}

function fetchNotes({
  quickbloxUserId,
  setNotes,
}: {
  quickbloxUserId: number
  setNotes: (notes: NoteInfo) => void
}): void {
  const userId = quickbloxUserId
  fetchAdmin({
    path: `notes/${userId}`,
    method: 'GET',
  })
    .then((res) => res.json())
    .then((json) => {
      setNotes(json)
    })
}

export default UserDetails
