import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useState } from 'react'
import { TIMEZONES } from '../../constants'
import { fetchAdmin } from '../../services/fetch'
import { getStorage, setStorage } from '../../services/storage'

function AnalyticsCampaign() {
  const [from, setFrom] = useState<moment.Moment | null>(
    moment().subtract(7, 'day'),
  )
  const [to, setTo] = useState<moment.Moment | null>(
    moment().subtract(1, 'day'),
  )
  const [campaignId, setCampaignId] = useState('1')
  const [timezone, setTimezone] = useState(
    getStorage('timezone', TIMEZONES[0].name),
  )
  const [status, setStatus] = useState({
    isLoading: false,
    rows: [] as Array<Record<string, any>>,
    columns: columnsInvitation,
  })
  const updateStatus = (newStatus: object) => {
    setStatus((old) => ({ ...old, ...newStatus }))
  }
  const onSubmit = () => {
    updateStatus({
      isLoading: true,
    })

    fetchAdmin({
      path: 'analytics/campaign',
      method: 'GET',
      queries: {
        from: from?.format('YYYYMMDD'),
        to: to?.format('YYYYMMDD'),
        campaignId,
        tz: timezone,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        updateStatus({
          isLoading: false,
          rows: json,
          columns: columns[campaignId],
        })
      })
      .catch(() => {})
  }
  const changeTimezone = (event: SelectChangeEvent<string>) => {
    const tz = event.target.value

    setStorage('timezone', tz)
    setTimezone(tz)
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <DatePicker
                  label="from"
                  value={from}
                  onChange={(value) => setFrom(value)}
                  format="YYYY/MM/DD"
                  slotProps={{
                    textField: {
                      sx: { maxWidth: 170 },
                    },
                  }}
                />

                <DatePicker
                  label="to"
                  value={to}
                  onChange={(value) => setTo(value)}
                  format="YYYY/MM/DD"
                  slotProps={{
                    textField: {
                      sx: { maxWidth: 170 },
                    },
                  }}
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <FormControl sx={{ minWidth: 250 }}>
                  <InputLabel id="campaign-type-select-label">
                    campaign type
                  </InputLabel>
                  <Select
                    labelId="campaign-type-select-label"
                    id="campaign-type-select"
                    value={campaignId}
                    label="campaign type"
                    onChange={(ev) => setCampaignId(ev.target.value)}
                  >
                    <MenuItem value="1">Referral from ambassadors</MenuItem>
                    <MenuItem value="2">Referral from public</MenuItem>
                    <MenuItem value="3">Moment marathon</MenuItem>
                    <MenuItem value="4">Chat master</MenuItem>
                    <MenuItem value="5">Moment marathon (1week)</MenuItem>
                    <MenuItem value="6">
                      Moment marathon (2023/04/11 start)
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 100 }}>
                  <InputLabel id="campaign-timezone-select-label">
                    timezone
                  </InputLabel>
                  <Select
                    labelId="campaign-timezone-select-label"
                    id="campaign-timezone-select"
                    value={timezone}
                    label="timezone"
                    onChange={changeTimezone}
                  >
                    {TIMEZONES.map((tz) => (
                      <MenuItem
                        key={`campaign-menuitem-${tz.short}`}
                        value={tz.name}
                      >
                        {tz.short}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </CardContent>

          <CardActions>
            <Button variant="outlined" onClick={onSubmit}>
              Submit
            </Button>
          </CardActions>
        </Card>
      </LocalizationProvider>

      <DataGrid
        loading={status.isLoading}
        columns={status.columns}
        rows={status.rows}
        disableColumnFilter
        disableColumnMenu
        hideFooter
      />
    </>
  )
}

const columnsInvitation: GridColumns = [
  {
    field: 'ymd',
    headerName: '',
    width: 100,
  },
  {
    field: 'countEntryAll',
    headerName: 'Total entry',
    width: 100,
    align: 'right',
  },
  {
    field: 'countEntryDay',
    headerName: 'Today entry',
    width: 100,
    align: 'right',
  },
  {
    field: 'countUserInvitedDay',
    headerName: 'Invite user',
    width: 100,
    align: 'right',
  },
  {
    field: 'countUserAchievedDay',
    headerName: 'Achieved user',
    width: 100,
    align: 'right',
  },
]

const columnsMoment: GridColumns = [
  {
    field: 'ymd',
    headerName: '',
    width: 100,
  },
  {
    field: 'countEntryAll',
    headerName: 'Total entry',
    width: 100,
    align: 'right',
  },
  {
    field: 'countEntryDay',
    headerName: 'Today entry',
    width: 100,
    align: 'right',
  },
  {
    field: 'postCount',
    headerName: 'Post user',
    width: 100,
    align: 'right',
  },
  {
    field: 'firstCount',
    headerName: 'First post user',
    width: 100,
    align: 'right',
  },
  {
    field: 'continuousCount',
    headerName: 'Continuous user',
    width: 100,
    align: 'right',
  },
  {
    field: 'achievedCount',
    headerName: 'Achieved user',
    width: 100,
    align: 'right',
  },
]

const columnsChatMaster: GridColumns = [
  {
    field: 'ymd',
    headerName: '',
    width: 100,
  },
  {
    field: 'countEntryAll',
    headerName: 'Total entry',
    width: 100,
    align: 'right',
  },
  {
    field: 'countEntryDay',
    headerName: 'Today entry',
    width: 100,
    align: 'right',
  },
  {
    field: 'createRoomCount',
    headerName: 'Create room',
    width: 100,
    align: 'right',
  },
  {
    field: 'createRoomUserCount',
    headerName: 'Create room user',
    width: 100,
    align: 'right',
  },
  {
    field: 'joinMemberCount',
    headerName: 'Join member',
    width: 100,
    align: 'right',
  },
  {
    field: 'joinRoomCount',
    headerName: 'Join room',
    width: 100,
    align: 'right',
  },
  {
    field: 'joinRoomMasterCount',
    headerName: 'Join room master',
    width: 100,
    align: 'right',
  },
]

const columns: { [key: string]: GridColumns } = {
  1: columnsInvitation,
  2: columnsInvitation,
  3: columnsMoment,
  4: columnsChatMaster,
  5: columnsMoment,
  6: columnsMoment,
}

export default AnalyticsCampaign
